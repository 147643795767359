// 获取SceneParam参数
const getSceneParamUrl = `/gateway/hc-mini/qrcode/getSceneParam`;
//获取二维码
const getCodeUrl = `/gateway/hc-mini/qrcode/getWxAppQrCodeNew`;
// 商家匹配用户标签身份识别接口
const userLabelRecognizeProcessUrl = `/gateway/blade-system/label/userLabelRecognizeProcess`;
// 判断是否认证人才
const checkIsAudit = `/gateway/hc-serve/manage/talents/checkIsAudit`;

export {
  getCodeUrl,
  getSceneParamUrl,
  userLabelRecognizeProcessUrl,
  checkIsAudit,
};
